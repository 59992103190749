// Filters

$(function() {
  'use strict';

  $('.js-filters-placeholder').on('click', function(event) {
    event.preventDefault();

    var $this = $(this),
        $filters = $this.parents('.js-filters');

    $filters.toggleClass('is-active');
  });

});