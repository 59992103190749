// Ne pas utiliser car Uglyfy renvoie l'erreur 'Unexpected token: operator «>»'

(_axcb = window._axcb || []).push(function(sdk) {
    sdk.on('cookies:complete', function(choices) {
      document
        .querySelectorAll('[data-hide-on-vendor-consent]')
        .forEach(function(el) {
            var vendor = el.getAttribute('data-hide-on-vendor-consent');
            el.style.display = choices[vendor] ? 'none' : 'inherit';
        });
      document
        .querySelectorAll('[data-requires-vendor-consent]')
        .forEach(function(el) {
            var vendor = el.getAttribute('data-requires-vendor-consent');
            if (choices[vendor]) {
              el.setAttribute('src', el.getAttribute('data-src'));
            }
        });
    });
});
