// Popin

$(function() {
  'use strict';

  if($('.js-popin').length > 0) {
    var $popin   = $('.js-popin'),
        $trigger = $('.js-popin-trigger'),
        $target  = $('.js-popin-target'),
        $close   = $('.js-popin-close'),
        cookieId = 'popin',
        hasCookie = getCookie(cookieId);

    $target.hide();

    if(!hasCookie) {
      $popin.show();
      $close.on('click', function(event) {
        event.preventDefault();

        setCookie(cookieId, '1');
        $popin.hide();
      });

      $trigger.on('click', function(event) {
        event.preventDefault();

        $trigger.hide();
        $target.show();

        $target.find('input[type="email"]').focus();
      })
    }
  }

});
