// Menu

$(function() {
  'use strict';

  var $items  = $('.js-navigation-item'),
      $subs   = $('.js-navigation-subcontainer'),
      $burger = $('.js-burger');

  $('.js-navigation-link').on('click', function(event) {
    event.preventDefault();

    var $this  = $(this),
        $item  = $this.parents('.js-navigation-item'),
        $sub   = $item.find('.js-navigation-subcontainer'),
        height = $sub.find('.js-navigation-subitems').outerHeight();

    if($item.hasClass('is-active')) {
      $item.removeClass('is-active');
      $subs.css('height', 0);
    }

    else {
      $items.not($item).removeClass('is-active');
      $subs.not($sub).css('height', 0);
      $item.toggleClass('is-active');
      $sub.css('height', height + 'px');
    }
  });

  $burger.on('click', function(event) {
    event.preventDefault();

    $body.toggleClass('active-nav');
  });

});
