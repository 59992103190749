// Slider

$(function() {
  'use strict';

  $('.js-slider-quarters .js-slider-items').slick({
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    prevArrow: $('.js-slider-prev').html(),
    nextArrow: $('.js-slider-next').html(),
    responsive: [
      {
        breakpoint: 960,
        settings: "unslick"
      }
    ]
  });

  $('.js-slider-full .js-slider-items').slick({
    autoplay: false,
    autoplaySpeed: 2000,
    dots: true,
    dotsClass: 'c-slider__dots',
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: $('.js-slider-prev').html(),
    nextArrow: $('.js-slider-next').html()
  });

  $('.js-slider-fifths .js-slider-items').slick({
    autoplay: true,
    autoplaySpeed: 1500,
    dots: false,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    prevArrow: $('.js-slider-prev').html(),
    nextArrow: $('.js-slider-next').html(),
    responsive: [
      {
        breakpoint: 960,
        settings: "unslick"
      }
    ]
  });

});