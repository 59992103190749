// Carousel (home)

$(function() {
  'use strict';

  $('.js-carousel-items').slick({
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    draggable: false,
    fade: true,
    pauseOnFocus: false,
    pauseOnHover: false,
    speed: 1000,
    swipe: false,
    touchMove: false
  });

});