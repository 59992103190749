// Magicfix

$(function() {
  'use strict';

  if($('.js-magicfix-object').length > 0 && $('.js-magicfix-referencial').length > 0 && $('.js-magicfix-container').length > 0) {

    // VARIABLES

      // Elements
      var $object       = $('.js-magicfix-object'),
          $referencial  = $('.js-magicfix-referencial'),
          $container    = $('.js-magicfix-container');

      // Positions & sizes
      var referencialBottom = $referencial.offset().top + $referencial.outerHeight(),
          objectBottom      = $object.offset().top + $object.outerHeight(),
          objectHeight      = $object.outerHeight();

      // Scroll data
      var didScroll     = true,
          scrollTop     = $win.scrollTop(),
          lastScrollTop = 0,
          from          = $object.offset().top,
          to            = $container.offset().top + $container.outerHeight();


    // Manage resize
    $win.on('resize', function() {

      // Positions & sizes
      referencialBottom = $referencial.offset().top + $referencial.outerHeight(),
      objectBottom      = $object.offset().top + $object.outerHeight(),
      objectHeight      = $object.outerHeight();

      // Scroll data
      from = $object.offset().top,
      to   = $container.offset().top + $container.outerHeight();
    });


    // Trigger scroll
    $win.on('scroll', function() {
      didScroll = true;
    });


    // Loop
    (function loop() {
      requestAnimationFrame(loop);
      
      if (didScroll) {

        // Re-define variables
        scrollTop         = $win.scrollTop();
        referencialBottom = $referencial.offset().top + $referencial.outerHeight();
        objectBottom      = $object.offset().top + $object.outerHeight();

        // Before zone
        if (referencialBottom <= from) {
          $object.css('position', 'absolute');
          $object.css('top', 0);
          $object.css('bottom', 'auto');
        }

        // After zone
        else if (referencialBottom + objectHeight >= to) {
          $object.css('position', 'absolute');
          $object.css('top', 'auto');
          $object.css('bottom', 0);
        }

        // Inside zone
        else {
          $object.css('position', 'fixed');
          $object.css('top', referencialBottom - scrollTop);
          $object.css('bottom', 'auto');
        }
        
        // Re-define variables
        lastScrollTop = scrollTop;
        didScroll = false;
      }
    })();

  }
});
