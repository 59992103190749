// Accordion

$(function() {
  'use strict';

  if($('.js-accordion').length > 0) {

    $('.js-accordion-trigger').on('click', function(event) {
      event.preventDefault();

      var $trigger   = $(this),
          $accordion = $trigger.parents('.js-accordion'),
          $target    = $accordion.find('.js-accordion-target'),
          $content   = $target.find('.js-accordion-content'),
          height     = $content.outerHeight();

      $accordion.toggleClass('is-active');

      if($accordion.hasClass('is-active')) {
        $target.css('height', height + 'px');
      }
      else {
        $target.css('height', '0');
      }
    });

    $('.js-accordion-trigger').first().trigger('click');

    $win.resize(function(event) {
      
      if($('.js-accordion.is-active').length > 0) {

        $('.js-accordion.is-active').each(function() {
          var $accordion = $(this),
              $target    = $accordion.find('.js-accordion-target'),
              $content   = $target.find('.js-accordion-content'),
              height     = $content.outerHeight();

          $target.css('height', height + 'px');
        });
      }
    });
  }

});