// Header

$(function() {
  'use strict';

  var $win = $(window),
      $header = $('.l-header'),
      $main = $('.l-main'),
      $pre = $('.l-header__container'),
      $banner = $('.l-header__banner'),
      $bannerClose = $('.l-header__banner-button'),
      cookieId = 'header-banner',
      didScroll = true,
      lastScrollTop = 0,
      headerTop = 0,
      hasCookie = getCookie(cookieId);

  var initScroll = function () {
    $win.on('scroll', function () {
      didScroll = true;
    });
  };
  setTimeout(initScroll, 250);

  $win.on('resize', function () {
    didScroll = true;
  });

  if($banner.length > 0) {
    if(hasCookie) {
      $banner.html('');
    } else {
      $banner.addClass('is-init');
      $bannerClose.one('click', function () {
        setCookie(cookieId, '1');
        $banner.html('');
        $banner.removeClass('is-init');
        didScroll = true;
      });
    }
  }

  (function loop() {
    requestAnimationFrame(loop);

    if (didScroll) {
      var scrollTop = $win.scrollTop(),
          scrollDiff = lastScrollTop - scrollTop,
          headerOffset = parseInt($header.css('padding-top')) || 0,
          bannerHeight = $banner.length > 0 ? $banner.outerHeight() : 0,
          headerHeight = bannerHeight + $pre.outerHeight();

      $main.css('padding-top', $header.outerHeight());
      $header.get(0).style.setProperty('--header-height', $header.outerHeight() + 'px');

      if (scrollTop <= 0) {
        // At top (show nav)
        headerTop = 0;
        $body.removeClass('is-not-at-top');
      } else {
        $body.addClass('is-not-at-top');

        if(scrollDiff > 0) {
          // Scrolling up (show nav)
          headerTop = Math.min(- Math.min(scrollTop, headerOffset), headerTop + scrollDiff);

          if($body.hasClass('fixed-nav') && headerTop != (- headerHeight)) {
            $body.removeClass('fixed-nav');
          }
        } else if(scrollDiff < 0) {
          // Scrolling down (hide nav)
          headerTop = Math.max(- headerHeight, headerTop + scrollDiff);

          if(!$body.hasClass('fixed-nav') && headerTop == (- headerHeight)) {
            $body.addClass('fixed-nav');
          }
        }
      }

      $header.css('position', 'fixed');
      $header.css(Modernizr.prefixed('transform'), 'translateY(' + headerTop + 'px)');

      lastScrollTop = scrollTop;

      didScroll = false;
    }
  })();

});
