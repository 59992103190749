// Pad

$(function() {
  'use strict';

  $('.js-pad').hover(function() {
    var $pad   = $(this),
        $descr = $pad.find('.js-pad-description'),
        $text  = $descr.find('.js-pad-text'),
        height = $text.outerHeight();

    $descr.css('height', height + 'px');
  }, function() {
    var $pad   = $(this),
        $descr = $pad.find('.js-pad-description');

    $descr.css('height', 0);
  });

});